import { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal, Notifications, RadioButtonGroup, TextField } from "@integrate/hedgehogger";
import {
  DataTable,
  DefaultDataTableParams,
  IDataTableParams,
  IParsedDataTableRow,
} from "../dataTable/DataTable";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { ClaimDataType, ClaimsService, PostClaimBody } from "../common/services/ClaimsService";
import { ModalGrid, Wrapper } from "../shared/StyledComponents";
import { PageHeader } from "../pageHeader/PageHeader";
import { TableHeight } from "../shared/StyleConstants";
import { IRadioOption } from "@integrate/hedgehogger/lib/components/RadioButtonGroup";
import { ConfirmationModal } from "../common/components/ConfirmationModal/ConfirmationModal";

const claimStageOptions: IRadioOption[] = [
  {
    label: "Internal",
    id: "Internal",
  },
  {
    label: "Beta",
    id: "Beta",
  },
  {
    label: "Release",
    id: "Release",
  }
];

export const ClaimsPage: FunctionComponent = () => {
  const [data, setData] = useState<IParsedDataTableRow[]>([]);
  const [totalClaims, setTotalClaims] = useState(0);
  const [params, setParams] = useState<IDataTableParams>(
    DefaultDataTableParams
  );
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications]: any[] = useState([]);
  const [claimsModalVisible, setClaimsModalVisible] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [claimOperation, setClaimOperation] = useState("Edit");
  const [newClaimKey, setNewClaimKey] = useState("");
  const [newClaimLabel, setNewClaimLabel] = useState("");
  const [newClaimDesc, setNewClaimDesc] = useState("");
  const [selectedClaimId, setSelectedClaimId] = useState("");
  const [newClaimKeyError, setNewClaimKeyError] = useState("");
  const [newClaimLabelError, setNewClaimLabelError] = useState("");
  const [newClaimStage, setNewClaimStage] = useState(claimStageOptions[0].id);
  const validKeyRegex = /^[_-]*[a-zA-Z0-9]+([_-][a-zA-Z0-9]+)*[_-]*$/;

  const getClaims = async () => {
    try {
      setLoading(true);
      const results = await ClaimsService.getClaims(params);
      if (!results) {
        throw new Error("error getting claims");
      }
      const parsedRows = ClaimsService.transformClaimsToRows(results, onEditClaim, onDeleteRowAction);

      setTotalClaims(results.totalCount);
      setData(parsedRows);
    } catch (e) {
      setNotifications([
        NotificationHelper.error("Unable to get claims, please try again."),
      ]);
    }

    setLoading(false);
  };

  useEffect(() => {
    getClaims();
  }, [
    params.search,
    params.skip,
    params.take,
    params.sortBy,
    params.sortOrder,
  ]);

  const handleTableParamsChange = (newParams: IDataTableParams) => {
    setParams(newParams);
  };

  const handleCreateClaim = () => {
    setClaimOperation("Create");
    setClaimsModalVisible(true);
  }

  const handleHideModal = () => {
    setNewClaimKeyError("");
    setNewClaimLabelError("");
    setNewClaimKey("");
    setNewClaimLabel("");
    setNewClaimDesc("");
    setSelectedClaimId("");
    setNewClaimStage(claimStageOptions[0].id);
    setClaimsModalVisible(false);
  };

  const createNewClaim = async () => {
    const newClaim: PostClaimBody = {
      key: newClaimKey,
      label: newClaimLabel,
      description: newClaimDesc,
      stage: newClaimStage,
    }

    try {
      const results = await ClaimsService.postClaim(newClaim);
      if (!results) {
        throw new Error("error posting claim");
      }

      setNotifications([NotificationHelper.success("Successfully created your new claim")]);
    } catch {
      setNotifications([NotificationHelper.error("Unable to create a new claim, please try again.")]);
    }

    getClaims();
    handleHideModal();
  }

  const editClaim = async () => {
    const newClaim: ClaimDataType = {
      id: selectedClaimId,
      key: newClaimKey,
      label: newClaimLabel,
      description: newClaimDesc,
      stage: newClaimStage,
    }

    try {
      const results = await ClaimsService.editClaim(newClaim);
      if (!results) {
        throw new Error("error editing claim");
      }

      setNotifications([NotificationHelper.success("Successfully edited your claim")]);
    } catch {
      setNotifications([NotificationHelper.error("Unable to edit claim, please try again.")]);
    }

    handleHideModal();
    getClaims();
  }

  const onChangeClaimKey = (key: string) => {
    setNewClaimKey(key);

    const isValid = validKeyRegex.test(key);
    if (!isValid) {
      setNewClaimKeyError("Only alphanumeric characters allowed with underscores or dashes as dividers.");
    } else {
      setNewClaimKeyError("");
    }
  }

  const onChangeClaimLabel = (label: string) => {
    setNewClaimLabel(label);
  }

  const onChangeDescription = (description: string) => {
    setNewClaimDesc(description);
  }

  const onChangeClaimStage = (stage: string) => {
    setNewClaimStage(stage);
  }

  const onEditClaim = (claim: ClaimDataType) => {
    setClaimOperation("Edit");
    setNewClaimKey(claim.key);
    setNewClaimLabel(claim.label);
    setNewClaimDesc(claim.description);
    setNewClaimStage(claim.stage);
    setSelectedClaimId(claim.id);
    setClaimsModalVisible(true);
  }

  const handleConfirmDeleteClaims = async () => {
    try {
      const results = await ClaimsService.deleteClaim(selectedClaimId);
      if (!results) {
        throw new Error("error deleting claims");
      }

      setNotifications([NotificationHelper.success("Successfully deleted claim")]);
    } catch {
      setNotifications([NotificationHelper.error("Unable to remove this claim, please try again.")]);
    }
    handleHideConfirmModal();
    getClaims();
  }

  const onDeleteRowAction = (claim: ClaimDataType) => {
    setShowConfirmationModal(true);
    setSelectedClaimId(claim.id);
  }

  const handleConfirmModal = () => {
    if (claimOperation === "Edit") {
      editClaim();
    } else {
      createNewClaim();
    }
  }

  const generateModalTitle = () => {
    return `${claimOperation} a Claim`;
  }

  const handleHideConfirmModal = () => {
    setShowConfirmationModal(false);
    setSelectedClaimId("");
  }

  const hasFormError = !!newClaimKeyError.length || !newClaimLabel.length || !newClaimKey.length || !newClaimDesc.length;

  return (
    <Wrapper>
      <PageHeader title="Claims"></PageHeader>
      <DataTable
        name="Claims"
        columns={ClaimsService.tableHeaderColumns}
        data={data}
        onChangeParams={handleTableParamsChange}
        totalAmount={totalClaims}
        loading={loading}
        maxHeight={TableHeight}
        button={<Button label="Create Claim" icon={""} onClick={handleCreateClaim} width="120px"></Button>}
      ></DataTable>
      <Notifications notifications={notifications} duration={4000} />
      <Modal 
        closeOnBlur
        closeCallback={handleHideModal}
        title={generateModalTitle()}
        active={claimsModalVisible}
        width="500px"
        buttons={[
          <Button
            label="Cancel"
            onClick={handleHideModal}
            type="text"
            key="cancelBtn">
          </Button>,
          <Button
            type="primary"
            key="confirmClaimBtn"
            dataTestId="confirmClaimBtn"
            label={claimOperation}
            disabled={hasFormError}
            onClick={handleConfirmModal}>
          </Button>,
        ]}
        >
          <ModalGrid>
            <TextField
              label="Key"
              defaultValue={newClaimKey}
              required
              placeholder="feature_claim"
              onChange={onChangeClaimKey}
              errorText={newClaimKeyError}
              dataTestId="claim_key_field"
            />
            <TextField
              label="Label"
              defaultValue={newClaimLabel}
              required
              placeholder="Feature Claim"
              onChange={onChangeClaimLabel}
              errorText={newClaimLabelError}
              dataTestId="claim_label_field"
            />
            <TextField
              label="Description"
              defaultValue={newClaimDesc}
              placeholder="Some text about what it does"
              required
              onChange={onChangeDescription}
              dataTestId="claim_desc_field"
            />
            <RadioButtonGroup
              label="Release Stage"
              defaultSelected={newClaimStage}
              onChange={onChangeClaimStage}
              options={claimStageOptions}
              dataTestId="claim_stage_radio"
            ></RadioButtonGroup>
          </ModalGrid>
        </Modal>
        <ConfirmationModal
          label={`This claim will be deleted!`}
          active={showConfirmationModal}
          onCancel={handleHideConfirmModal}
          onConfirm={handleConfirmDeleteClaims}
        />
    </Wrapper>
  );
};
