
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useDebounce } from "../hooks/useDebounce";
import {
  KeywordSearch,
  Pagination,
  Dropdown,
  Button,
  Styles,
  Table,
} from "@integrate/hedgehogger";


export interface TableSelection {
  id: string;
  isSelected: boolean;
}

import {
  HeaderWrapper,
  Footer,
  MainBody,
  DropdownWrapper,
  FormLabel,
} from "../shared/StyledComponents";
import { DropdownItem } from "@integrate/hedgehogger/lib/components/dropdown/DropdownOptions";
import styled from "styled-components";
import { TableHeight } from "../shared/StyleConstants";

const ActionButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 3;

  button {
    margin-left: 16px;
  }
`;

interface BoldTextProps {
  opacity: string;
}

const BoldText = styled.h2<BoldTextProps>`
  font-size: ${Styles.fontSizes.m2};
  font-weight: 400;
  margin: 0;
  align-self: center;
  margin-top: 6px;
  opacity: ${({ opacity }) => opacity};

`

const BulkWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`

export interface IDataTableHeader {
  label: string;
  id: string;
  width?: string;
  dataTestId?: string;
  hideDisabled?: boolean;
  sortASC?: any;
  sortDES?: any;
  clearSort?: any;
  disableSort?: boolean;
}

export interface IDataTableColumn {
  id: string;
  value: string | ReactElement | number;
}

export interface IDataTableRowAction {
  label: string;
  action?: () => void;
  dataTestId?: string;
}

export interface IParsedDataTableRow {
  id: string;
  columns: IDataTableColumn[];
  actions?: IDataTableRowAction[];
  isDefaultChecked?: boolean;
}

export interface IDataTableParams {
  search: string;
  skip: number;
  take: number;
  sortBy: string;
  sortOrder: string;
}

export interface IBulkActionOptions {
  onBulkItemClicked: (action: DropdownItem) => void;
  bulkActionItems: DropdownItem[];
}

export const DefaultDataTableParams: IDataTableParams = {
  search: "",
  skip: 0,
  take: 25,
  sortBy: "",
  sortOrder: "",
};

interface IDataTableProps {
  name: string;
  columns: IDataTableHeader[];
  data: any[];
  onChangeParams: (params: IDataTableParams) => void;
  totalAmount: number;
  showFilter?: boolean;
  button?: ReactElement;
  popoverWidth?: string;
  onSelectItems?: (items: TableSelection[]) => void;
  showPagination?: boolean;
  loading?: boolean;
  bulkActionOptions?: IBulkActionOptions;
  maxHeight?: string,
  numSelectedItems?: number,
}

export const DataTable: FunctionComponent<IDataTableProps> = ({
  name,
  columns,
  data,
  totalAmount,
  onChangeParams,
  button,
  popoverWidth = "120px",
  onSelectItems,
  showPagination = true,
  loading = false,
  bulkActionOptions = null,
  maxHeight = TableHeight,
  numSelectedItems = 0,
}) => {
  const [take, setTake] = useState(DefaultDataTableParams.take);
  const [skip, setSkip] = useState(DefaultDataTableParams.skip);
  const [searchTerm, setSearchTerm] = useState(DefaultDataTableParams.search);
  const [sortOrder, setSortOrder] = useState(DefaultDataTableParams.sortOrder);
  const [sortBy, setSortBy] = useState(DefaultDataTableParams.sortBy);
  const debouncedSearchQuery = useDebounce(searchTerm, 500);

  useEffect(() => {
    onChangeParams({
      search: debouncedSearchQuery.trim(),
      skip: skip,
      take: take,
      sortBy: sortBy,
      sortOrder: sortOrder,
    });
  }, [debouncedSearchQuery, skip, take, sortOrder, sortBy]);

  const handleSelection = (items: TableSelection[]) => {
    if (onSelectItems) {
      onSelectItems(items);
    }
  };

  const handleShowTakeDropdown = (selected: any) => {
    setTake(parseInt(selected.value));
    setSkip(0);
  };

  const handleChangeSearch = (term: string) => {
    setSearchTerm(term);
    setSkip(0);
  };

  const handlePageChange = (page: number) => {
    const newSkip = (page - 1) * take;
    setSkip(newSkip);
    if (onSelectItems) {
      onSelectItems([]);
    }
  };

  const sortData = (order: string, column: string) => () => {
    setSortOrder(order);
    setSortBy(column);
  };

  const preparedHeaderColumns = columns.map((column: IDataTableHeader) => {
    if (column.disableSort) {
      return column;
    }

    column.clearSort = sortData("", "");
    column.sortASC = sortData("asc", column.id);
    column.sortDES = sortData("desc", column.id);

    return column;
  });

  const takeOptions: DropdownItem[] = [
    {
      id: "1",
      value: "25",
      isSelected: take === 25,
    },
    {
      id: "2",
      value: "50",
      isSelected: take === 50,
    },
    {
      id: "3",
      value: "100",
      isSelected: take === 100,
    },
  ];

  const renderBulkActions = (): ReactElement => {
    return (
      <BulkWrapper>
        {onSelectItems && <BoldText opacity={numSelectedItems ? "0.9" : "0.3"}>{`${numSelectedItems} selected`}</BoldText>}
        {bulkActionOptions && bulkActionOptions.bulkActionItems
          ? <Button
              type="dropdown"
              label="Bulk Actions"
              onClick={() => null}
              disabled={numSelectedItems === 0}
              onDropdownClick={bulkActionOptions.onBulkItemClicked}
              dropdownOptions={bulkActionOptions.bulkActionItems}
            ></Button>
          : ""
        }
      </BulkWrapper>
    )
  };

  const amountOfPages = Math.ceil(totalAmount / take);
  const skipAndTake = skip + take;
  const selectable = onSelectItems !== undefined;

  const isRightFixed = () => {
    if (data[0]?.actions?.length) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <HeaderWrapper >
        <KeywordSearch
          placeholder={`Search ${name}`}
          onChange={handleChangeSearch}
          width={"100%"}
        ></KeywordSearch>
        <ActionButtonWrapper>
          {renderBulkActions()}
          {button}
        </ActionButtonWrapper>
      </HeaderWrapper>
      <MainBody>
        <Table
          rightFixed={isRightFixed()}
          actionPopoverWidth={popoverWidth}
          onSelect={handleSelection}
          selectable={selectable}
          maxHeight={maxHeight}
          columns={preparedHeaderColumns}
          width={"100%"}
          data={data}
          hideColumns
          loading={loading}
        ></Table>
      </MainBody>
      {showPagination && (
        <Footer>
          <FormLabel>
            Showing {`${skip + 1}-${skipAndTake} of`} {totalAmount} {name}
          </FormLabel>
          <Pagination
            amountOfPages={amountOfPages}
            onChange={handlePageChange}
          ></Pagination>
          <DropdownWrapper>
            <Dropdown
              placeholder={"25"}
              popoverWidth={"100px"}
              label={"Show"}
              onClick={handleShowTakeDropdown}
              items={takeOptions}
            ></Dropdown>
          </DropdownWrapper>
        </Footer>
      )}
      {!showPagination && (
        <Footer>
          <FormLabel>
            Showing {totalAmount} {name}
          </FormLabel>
        </Footer>
      )}
    </div>
  );
};
