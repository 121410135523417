import { Button, Notifications } from "@integrate/hedgehogger";
import { FunctionComponent, useEffect, useState } from "react";
import { DataTable, DefaultDataTableParams, IDataTableParams, IParsedDataTableRow, TableSelection } from "../../dataTable/DataTable";
import { OrgDataType, OrgService } from "../../common/services/OrgService";
import {
  DeleteOrgClaimsBody,
  ClaimsService,
  ClaimDataType,
  ClaimsDataType,
} from "../../common/services/ClaimsService";
import { BulkModal, BulkModes, StepEnums } from "../../common/components/BulkModal/BulkModal";
import {
  UserDataType,
  UsersDataType,
  UsersService,
  OrgClaim,
} from "../../common/services/UsersService";
import { NotificationHelper } from "../../common/helpers/NotificationHelper";
import { DropdownItem } from "@integrate/hedgehogger/lib/components/dropdown/DropdownOptions";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "../../common/components/ConfirmationModal/ConfirmationModal";
import { TableHelper } from "../../common/helpers/TableHelper";

interface OrgUsersProps {
  data: OrgDataType;
}

export const OrgUsers: FunctionComponent<OrgUsersProps> = ({ data }) => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [tableData, setTableData] = useState<IParsedDataTableRow[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [orgUsers, setOrgUsers] = useState<UsersDataType|undefined>();
  const [sanitizedOrgUsers, setSanitizedOrgUsers] = useState<UsersDataType>();
  const [selectedUsers, setSelectedUsers] = useState<UserDataType[]>([]);
  const [selectedClaims, setSelectedClaims] = useState<ClaimDataType[]>([]);
  const [isAssignMode, setIsAssignMode] = useState(false);
  const [active, setActive] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepEnums>(StepEnums.SelectClaims);
  const [userToDelete, setUserToDelete] = useState<UserDataType>();
  const [verifiedClaimsSet, setVerifiedClaimsSet] = useState<Set<string>>(new Set<string>());

  const orgId = data.id;
  const history = useHistory();
  const orgsData: OrgDataType[] = [data];

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await OrgService.getOrgUsers(data, params);
      if (!response) {
        throw new Error("undefined users");
      }
      setOrgUsers(response);
      setTotalUsers(response.totalCount);
    } catch (e) {
      setNotifications([NotificationHelper.error("Unable to get users, please try again.")]);
    }

    setLoading(false);
  };

  const applyClaimsToEachUser = (
    users: UsersDataType,
    userClaimsMap: Map<string, Set<string>>,
  ): UsersDataType => {

    const clonedUsers = {...users};
    if (clonedUsers && clonedUsers.users) {
      // Go through each user and apply their sanitized claims.
      clonedUsers.users.forEach((user: UserDataType) => {
        const orgIndex = user.organizationClaims.findIndex(
          (orgClaim: OrgClaim) => orgClaim.organizationId === orgId
        );

        if (orgIndex !== -1) {
          const claimsSet = userClaimsMap.get(user.id);
          if (claimsSet) {
            user.organizationClaims[orgIndex].claims = Array.from(claimsSet.values());
          }
        }
      });
    }

    return clonedUsers;
  };

  const buildUserToClaimsMap = (users: UserDataType[], orgId: string): Map<string, Set<string>> => {
    const mappedClaims = new Map<string, Set<string>>();
    // Generate userId -> claims map for this org.
    users.forEach((user: UserDataType) => {
      const orgClaim = user.organizationClaims.find(
        (orgClaim: OrgClaim) => orgClaim.organizationId === orgId
      );

      if (orgClaim) {
        // Deduplicate and remove invalid claims.
        mappedClaims.set(
          user.id,
          ClaimsService.buildSanitizedClaimsSet(orgClaim.claims, verifiedClaimsSet)
        );
      }
    });

    return mappedClaims;
  };

  const getClaims = async (): Promise<ClaimsDataType> => {
    const response = await ClaimsService.getClaims(ClaimsService.GET_ALL_CLAIMS_PARAMS);
    return response;
  };

  useEffect(() => {
    const fetchClaims = async () => {
      const response = await getClaims();
      if (response?.success && response?.claims) {
        // Convert to simple string array of claim keys.
        let claimsArray = response.claims.map((c) => c.key);
        // Append non-feature claim keys to the array. E.g. User, Buyer, Seller, etc.
        claimsArray = claimsArray.concat(ClaimsService.NON_FEATURE_CLAIMS);
        // Converts array to the Set data type.
        const claimsSet = ClaimsService.buildSanitizedClaimsSet(claimsArray);
        setVerifiedClaimsSet(claimsSet);
      }
    }
    fetchClaims();
  }, []);

  useEffect(() => {
    // Re-sanitize org users whenever users or claims data were fetched.
    const sanitizedUsers = sanitizeOrgUsersClaims(orgUsers, verifiedClaimsSet);
    setSanitizedOrgUsers(sanitizedUsers);
  }, [orgUsers, verifiedClaimsSet]);

  useEffect(() => {
    getUsers();
  }, [params.search, params.skip, params.take, params.sortBy, params.sortOrder]);

  useEffect(() => {
    if (sanitizedOrgUsers) {
      const parsedData = UsersService.transformUsersToRows(
        sanitizedOrgUsers,
        selectedUsers,
        handleEditUser,
        handleChangeUserClaims,
        handleUserDeleteConfirmation,
        data.id,
      );
      setTableData(parsedData);
    }
  }, [selectedUsers, sanitizedOrgUsers]);

  const sanitizeOrgUsersClaims = (
    users: UsersDataType | undefined,
    claimsSet: Set<string>
  ): UsersDataType | undefined => {

    let sanitizedUsers = users;
    if (users?.users && claimsSet.size > 0) {
      const mappedUserClaims = buildUserToClaimsMap(users.users, orgId);
      sanitizedUsers = applyClaimsToEachUser(users, mappedUserClaims);
    }
    return sanitizedUsers;
  };

  const bulkOptions: DropdownItem[] = [
    {
      id: "assign",
      value: "Assign Claims",
    },
    {
      id: "unassign",
      value: "Unassign Users",
    },
  ];

  const handleEditUser = (userId: string) => {
    history.push(`/users/${userId}`);
  };

  const handleChangeUserClaims = async (user: UserDataType) => {
    if (user) {
      const orgClaim = user.organizationClaims.find((oc) => oc.organizationId === data.id);
      const selectedClaims: ClaimDataType[] = [];
      if (orgClaim && orgClaim.claims && orgClaim.claims.length > 0) {
        orgClaim.claims.forEach((claim) => {
          selectedClaims.push({
            key: claim,
            label: claim,
            id: claim,
            description: "",
            stage: "",
          });
        });
      }
      setCurrentStep(StepEnums.SelectClaims);
      setSelectedClaims(selectedClaims);
      setSelectedUsers([user]);
      setIsAssignMode(true);
      setActive(true);
    }
  };

  const hideBulkModal = (refresh: boolean) => {
    setActive(false);
    setSelectedUsers([]);
    if (refresh) {
      getUsers();
    }
  };

  const handleCreateUser = async () => {
    setSelectedClaims([]);
    setIsAssignMode(true);
    setCurrentStep(StepEnums.SelectUsers);
    setActive(true);
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      const body: DeleteOrgClaimsBody = {
        userIds: [userToDelete.id],
        organizationIds: [data.id],
      };
      try {
        const sanitizedOrgUsers = await ClaimsService.deleteOrgClaims(body);
        if (!sanitizedOrgUsers) {
          throw new Error("error deleting user from the org");
        }
        setNotifications([NotificationHelper.success("Deleted one user from the org.")]);
        getUsers();
      } catch (e) {
        setNotifications([NotificationHelper.error("Unable to delete user, please try again.")]);
      }
    }
    setShowConfirmationModal(false);
  };

  const handleUserDeleteConfirmation = (user: UserDataType) => {
    setShowConfirmationModal(true);
    setUserToDelete(user);
  }

  const handleHideConfirmModal = () => {
    setShowConfirmationModal(false);
  }

  const handleChangeParams = (newParams: IDataTableParams) => {
    setParams(newParams);
  };

  const handleSetSelectedItems = (items: TableSelection[]) => {
    const selectedRows = TableHelper.calcSelectedRows(
      selectedUsers,
      items,
      sanitizedOrgUsers?.users ?? []
    );
    setSelectedUsers(selectedRows);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    setCurrentStep(StepEnums.SelectClaims);
    setSelectedClaims([]);
    switch (bulkActionItem.id) {
      case "assign":
        setIsAssignMode(true);
        setActive(true);
        break;
      case "unassign":
        setIsAssignMode(false);
        setActive(true);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <DataTable
        button={
          <Button
            onClick={handleCreateUser}
            label="Assign User"
            minWidth="84px"
            width="70%"
            maxWidth="210px"
            dataTestId="userButton"
          ></Button>
        }
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        name="Org's Users"
        columns={UsersService.tableHeaderColumnsWithClaims}
        data={tableData}
        onChangeParams={handleChangeParams}
        onSelectItems={handleSetSelectedItems}
        totalAmount={totalUsers}
        popoverWidth="120px"
        loading={loading}
        numSelectedItems={selectedUsers.length}
      ></DataTable>
      <BulkModal
        onCancel={hideBulkModal}
        selectedOrgs={orgsData}
        selectedUsers={selectedUsers}
        selectedClaims={selectedClaims}
        currentStepKey={currentStep}
        active={active}
        mode={isAssignMode ? BulkModes.AssignUsers : BulkModes.UnassignUsers}
      />
      <ConfirmationModal
        label={`${userToDelete?.userName} will be unassigned.`}
        active={showConfirmationModal}
        onCancel={handleHideConfirmModal}
        onConfirm={handleDeleteUser}
      />
      <Notifications notifications={notifications} duration={4000} />
    </div>
  );
};
