import axios, { AxiosRequestConfig } from 'axios';
import Environment from '../../environment.json';

export const client = axios.create({
  baseURL: Environment.host,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AUTH_TOKEN');

    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const onSuccessCallback = function(response: any) {
  return response.data;
};

const onErrorCallback = function(error: any) {
  return error?.response?.data;
};

// Wrapper for axios' requests.
export const httpRequest = function<T>(options: AxiosRequestConfig): Promise<T> {
  return client(options)
    .then(onSuccessCallback)
    .catch(onErrorCallback);
};

export default {
  httpRequest,
};
