import { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import { SaveButtonWrapper } from "../../shared/StyledComponents";
import {
  TextField,
  Switch,
  Button,
  Notifications,
} from "@integrate/hedgehogger";
import {
  OrgService,
  OrgDataType,
  LicenseData,
  GetLicenseData,
} from "../../common/services/OrgService";
import { ConfirmationModal } from "../../common/components/ConfirmationModal/ConfirmationModal";
import { ToggleButton } from "../../common/components/ToggleButton/ToggleButton";
import { LICENSE_COUNT, DropdownItem, ADVANCED_PC_LICENSE, EXCESS_LICENSE_ERROR_TEXT } from "../Constants";
import { Modal } from "@integrate/hedgehogger";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";

type OrgGeneralInfoProps = {
  data: OrgDataType;
};

const Grid2x2 = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns:400px 400px ;
  grid-template-rows: auto;
`;


const Grid = styled.div`
  display: grid;
  row-gap: 24px;
  margin-top: 24px;
`;

const SwitchWrapper = styled.label`
  display: inline-block;
  margin-top: 14px;
`;

const SwitchLabel = styled.label`
  display: inline-block;
  margin-left: 8px;
`;

const CustomDropdown = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  #license-dropdown {
    max-width: 400px;
  }
  label {
    font-size: 14.22px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

const overridedStyle=
  {
    control: (base:CSSObjectWithLabel) => ({
      ...base,
      height: "45px",
      border: "solid 2px #cfcfcf"
    }),
  }


export const OrgGeneralInfo: FunctionComponent<OrgGeneralInfoProps> = ({
  data,
}) => {
  const [shortName, setShortName] = useState(data.shortName || "");
  const [orgName, setOrgName] = useState(data.name || "");
  const [orgDesc, setOrgDesc] = useState(data.description || "");
  const [zuoraId, setZuoraId] = useState(data.subscriptionId || "");
  const [salesforceIds, setSalesforceIds] = useState("");
  const [isAgency, setIsAgency] = useState(data.isAgency);
  const [licenseData, setLicenseData] = useState<GetLicenseData>({
    organizationId: "",
    licenses: {},
  });
  const [selectedLicense, setSelectedLicense] = useState<number>(0);
  const [actionModal, setActionModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<DropdownItem>(LICENSE_COUNT[0]);

  // The IsDAPActive state stored in the database.
  const [persistedIsDAPActive, setPersistedIsDAPActive] = useState(
    data.isDAPActive
  );
  // The current/local value of IsDAPActive before it gets saved.
  const [isDAPActiveToggle, setIsDAPActiveToggle] = useState(data.isDAPActive);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [
    showCustomerDeactivationConfirmation,
    setShowCustomerDeactivationConfirmation,
  ] = useState(false);
  const [showSaveGeneralInfoConfirmation, setShowSaveGeneralInfoConfirmation] =
    useState(false);

  const advertiserData = data.advertiser;
  const id = data.id || "";
  const shortId = data.shortId || "";
  const customerDeactivationBodyText = `You are setting this customer to INACTIVE. By doing so, you are deactivating
    any non-marketplace sources and leads will NOT be processed. If you decide to reactivate this account at a
    different time, all non-narketplace sources will be set to live, and ONLY leads uploaded post-reactivation will be
    processed. Do you wish to continue?`;

  useEffect(() => {
    validateFormInputs();
  }, [shortName, orgName, orgDesc]);

  useEffect(() => {
    if (Array.isArray(data.crmAccountIds)) {
      setSalesforceIds(data.crmAccountIds.join(", "));
    } else {
      setSalesforceIds("");
    }
    getLicenseCount();
  }, [data.crmAccountIds]);

  useEffect(()  => {
    if(!data.advertiser?.success){
      setNotifications([
        ...notifications,
        {
          title: "Error",
          text: `Failed to retrieve advertiser details for the "${orgName}".`,
          type: "error",
          hasClose: false,
        },
      ]);
    }
  },[data]);

  const getLicenseCount = async () => {
    const response = await OrgService.getLicense(data.id);
    setLicenseData(response?.data);
    LICENSE_COUNT.forEach((element) => {
      if (element.value === response?.data?.licenses?.APC?.allowed.toString())
        setSelectedOption(element);
    });
  };
  const handleLicenseCountChange = (input: SingleValue<DropdownItem>): void => {
    if (input !== null) {
      setSelectedOption(input);
      const parsedInput = parseInt(input.value);
      setSelectedLicense(parsedInput);
      if (parsedInput > 0 && parsedInput < licenseData?.licenses?.APC?.availed) {
        setSelectedLicense(licenseData?.licenses?.APC?.allowed);
        setActionModal(true);
        LICENSE_COUNT.forEach((element) => {
          if (element.value === licenseData?.licenses?.APC?.allowed.toString())
            setSelectedOption(element);
        });
      }
    }
  };

  const saveGeneralInfoData = async () => {
    const transformed = OrgService.transformOrgDataForSaving(
      data.id,
      data.shortId,
      orgName,
      shortName,
      orgDesc,
      zuoraId,
      salesforceIds,
      isAgency,
      isDAPActiveToggle,
      data.dispositionMap
    );
    const response = await OrgService.saveOrg(transformed);
    if (selectedLicense !== licenseData?.licenses?.APC?.allowed) {
      const postData: LicenseData = {
        orgId: data.id,
        licenses: {
          APC: selectedLicense,
        },
      };
      OrgService.postLicense(postData).then(() => {
        getLicenseCount();
      });
    }

    if (response.organization) {
      setNotifications([
        ...notifications,
        {
          title: "Save Succeed",
          text: `Organization "${orgName}" has been updated.`,
          type: "success",
          hasClose: false,
        },
      ]);
      setPersistedIsDAPActive(isDAPActiveToggle);
    } else {
      setNotifications([
        ...notifications,
        {
          title: "Save Failed",
          text: `There's an error saving Organization "${orgName}".`,
          type: "error",
          hasClose: false,
        },
      ]);
    }
  };

  const handleOnSaveClicked = () => {
    if (!isDAPActiveToggle && persistedIsDAPActive) {
      setShowSaveGeneralInfoConfirmation(true);
    } else {
      saveGeneralInfoData();
    }
  };

  const validateFormInputs = (): void => {
    setIsSaveButtonDisabled(!orgName);
  };

  const handleOrgNameChange = (val: string): void => {
    setOrgName(val.trim());
  };

  const handleShortNameChange = (val: string): void => {
    setShortName(val.trim());
  };

  const handleZuoraIdChange = (val: string): void => {
    setZuoraId(val.trim());
  };

  const handleSalesforceIdsChange = (val: string): void => {
    setSalesforceIds(val.trim());
  };

  const handleDescChange = (val: string): void => {
    setOrgDesc(val.trim());
  };

  const handleIsAgencyChange = (val: boolean): void => {
    setIsAgency(val);
  };

  const handleIsDAPActiveChange = (): void => {
    const newIsActive = !isDAPActiveToggle;
    if (newIsActive) {
      setIsDAPActiveToggle(true);
    } else if (!newIsActive && persistedIsDAPActive) {
      setShowCustomerDeactivationConfirmation(true);
    } else {
      // We don't want to annoy users with the popup if the account is already inactive in the DB.
      setIsDAPActiveToggle(false);
    }
  };

  const handleCancelCustomerDeactivation = (): void => {
    setIsDAPActiveToggle(true);
    setShowCustomerDeactivationConfirmation(false);
  };

  const handleConfirmCustomerDeactivation = (): void => {
    setIsDAPActiveToggle(false);
    setShowCustomerDeactivationConfirmation(false);
  };

  const handleCancelSaveGeneralInfo = (): void => {
    setShowSaveGeneralInfoConfirmation(false);
  };

  const handleConfirmSaveGeneralInfo = (): void => {
    saveGeneralInfoData();
    setShowSaveGeneralInfoConfirmation(false);
  };

  return (
    <div>
      <Modal
        title="Action required"
        closeOnBlur
        closeCallback={() => setActionModal(false)}
        active={actionModal}
        width="600px"
        buttons={[
          <Button
            label="Ok, Understood"
            onClick={() => {
              setActionModal(false);
            }}
          />,
        ]}
      >
        <p>
          {EXCESS_LICENSE_ERROR_TEXT}
        </p>
      </Modal>
      <SaveButtonWrapper>
        <Button
          label="Save"
          onClick={handleOnSaveClicked}
          disabled={isSaveButtonDisabled}
        />
      </SaveButtonWrapper>
      <Grid2x2>
        <TextField label="ID" defaultValue={id} placeholder="" disabled/>
        <TextField
          label="Short ID"
          defaultValue={shortId}
          placeholder=""
          disabled
        />
        <TextField
          label="Name"
          defaultValue={orgName}
          placeholder="Enter the organization name"
          onChange={handleOrgNameChange}
        />
        <TextField
          label="Short Name"
          defaultValue={shortName}
          placeholder="Enter the organization short name"
          onChange={handleShortNameChange}
        />
        <TextField
          label="Advertiser ID"
          defaultValue={advertiserData?.advertiserId || ""}
          placeholder="Not Found"
          width="400px"
          disabled
        />
        <TextField
          label="Advertiser Name"
          defaultValue={advertiserData?.advertiserName || ""}
          placeholder="Not Found"
          width="400px"
          disabled
        />
        </Grid2x2>
        <Grid>
        <CustomDropdown>
          <label>{ADVANCED_PC_LICENSE}</label>
          <Select
            id="license-dropdown"
            value={selectedOption}
            options={LICENSE_COUNT}
            placeholder="Select count..."
            onChange={handleLicenseCountChange}
            isOptionDisabled={(option) => option.value === "Select Count..."}
            styles={overridedStyle}
          />
        </CustomDropdown>
        <TextField
          label="Zuora ID (optional)"
          defaultValue={zuoraId}
          placeholder="Enter Zuora ID"
          onChange={handleZuoraIdChange}
          width="400px"
        />
        <TextField
          label="Salesforce ID (optional)"
          defaultValue={salesforceIds}
          placeholder="Enter Salesforce ID"
          helperText="IDs are separated via commas"
          onChange={handleSalesforceIdsChange}
          width="400px"
        />
        <TextField
          label="Description (optional)"
          defaultValue={orgDesc}
          placeholder="Description of the organization"
          multiLine
          onChange={handleDescChange}
          maxWidth="824px"
        />
        </Grid>
      <div>
        <SwitchWrapper>
          <Switch isDefaultChecked={isAgency} onChange={handleIsAgencyChange} />
          <SwitchLabel>Is an Agency?</SwitchLabel>
        </SwitchWrapper>
      </div>
      <div>
        <SwitchWrapper>
          <ToggleButton
            isChecked={isDAPActiveToggle}
            onChange={handleIsDAPActiveChange}
            dataTestId="toggle-isdapactive"
          />
          <SwitchLabel>Is active DAP customer?</SwitchLabel>
        </SwitchWrapper>
      </div>
      <Notifications notifications={notifications} duration={4000} />
      <ConfirmationModal
        title="WARNING!"
        label={customerDeactivationBodyText}
        active={showCustomerDeactivationConfirmation}
        onCancel={handleCancelCustomerDeactivation}
        onConfirm={handleConfirmCustomerDeactivation}
      />
      <ConfirmationModal
        label="You will be deactivating ALL non-marketplace sources, do you still wish to continue?"
        active={showSaveGeneralInfoConfirmation}
        onCancel={handleCancelSaveGeneralInfo}
        onConfirm={handleConfirmSaveGeneralInfo}
      />
    </div>
  );
};
