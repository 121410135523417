import { FunctionComponent, useEffect, useState } from "react";
import { Button, Notifications, Modal, TextField, Checkbox, KeywordSearch } from "@integrate/hedgehogger";
import { DropdownItem } from "@integrate/hedgehogger/lib/components/dropdown/DropdownOptions";
import { Wrapper, SearchWrapper, FormLabel } from "../shared/StyledComponents";
import { DefaultDataTableParams, IDataTableParams } from "../dataTable/DataTable";
import { PageHeader } from "../pageHeader/PageHeader";
import { UsersService, CreateUserRequest, UserDataType } from "../common/services/UsersService";
import { OrgService, OrgsType, OrgDataType } from "../common/services/OrgService";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { useDebounce } from "../hooks/useDebounce";
import styled from "styled-components";
import { UsersTable } from "./UsersTable";
import { BulkModal, BulkModes, StepEnums } from "../common/components/BulkModal/BulkModal";

const ModalGrid = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  justify-items: stretch;
`;

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const EMPTY_ORG = OrgService.createEmptyOrgDataType();

export const UsersPage: FunctionComponent = () => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [disableCreateUserBtn, setDisableCreateUserBtn] = useState(true);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserBuyer, setIsUserBuyer] = useState(false);
  const [isUserSeller, setIsUserSeller] = useState(false);
  const [isUserAgency, setIsUserAgency] = useState(false);
  const [orgDropdownItems, setOrgDropdownItems] = useState<DropdownItem[]>([]);
  const [getOrgsParams, setGetOrgsParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [orgSearchTerm, setOrgSearchTerm] = useState("");
  const [selectedOrg, setSelectedOrg] = useState<OrgDataType>(EMPTY_ORG);
  const [selectedUsers, setSelectedUsers] = useState<UserDataType[]>([]);
  const debouncedOrgSearchTerm = useDebounce(orgSearchTerm, 500);
  const [isAssignMode, setIsAssignMode] = useState(false);
  const [active, setActive] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setDisableCreateUserBtn(
      !firstName ||
        !!firstNameError ||
        !lastName ||
        !!lastNameError ||
        !email ||
        !!emailError ||
        selectedOrg.id === "" ||
        // At least one of these checkboxes must be checked.
        !(isUserBuyer || isUserSeller || isUserAgency)
    );
  }, [
    firstName,
    lastName,
    email,
    firstNameError,
    lastNameError,
    emailError,
    isUserBuyer,
    isUserSeller,
    isUserAgency,
    selectedOrg,
  ]);

  useEffect(() => {
    const newParams = Object.assign({}, getOrgsParams);
    newParams.search = debouncedOrgSearchTerm.trim();
    setGetOrgsParams(newParams);
  }, [debouncedOrgSearchTerm]);

  useEffect(() => {
    const getOrgs = async () => {
      try {
        const results = await OrgService.getOrgs(getOrgsParams);
        if (!results) {
          throw new Error("error getting orgs");
        }
        transformOrgResults(results);
      } catch (e) {
        setNotifications([NotificationHelper.success("Unable to get orgs, please try again.")]);
      }
    };

    const transformOrgResults = (data: OrgsType) => {
      if (data) {
        const organizations = data.organizations;
        const parsedData: DropdownItem[] = organizations.map((org: OrgDataType, index: number) => {
          return {
            key: index,
            id: org.id,
            value: `${org.name} [SID: ${org.shortId}]`,
          };
        });
        setOrgDropdownItems(parsedData);
      }
    };
    if (getOrgsParams.search) {
      getOrgs();
    }

    setOrgSearchTerm("");
  }, [getOrgsParams.search]);

  const showCreateUserModal = () => {
    setCreateUserModalVisible(true);
  };

  const hideCreateUserModal = () => {
    resetCreateUserModal();
    setCreateUserModalVisible(false);
  };

  const resetCreateUserModal = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setSelectedOrg(EMPTY_ORG);
    setIsUserAdmin(false);
    setIsUserBuyer(false);
    setIsUserSeller(false);
    setIsUserAgency(false);
  };

  const onFirstNameChange = (val: string) => {
    const trimmedName = val.trim();
    setFirstName(trimmedName);
    if (trimmedName) {
      setFirstNameError("");
    } else {
      setFirstNameError("First name is required.");
    }
  };

  const onLastNameChange = (val: string) => {
    const trimmedName = val.trim();
    setLastName(trimmedName);
    if (trimmedName) {
      setLastNameError("");
    } else {
      setLastNameError("Last name is required.");
    }
  };

  const onEmailChange = (val: string) => {
    setEmail(val);
    if (EMAIL_REGEX.test(val)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const onUserBuyerChange = (val: boolean) => {
    setIsUserBuyer(val);
  };

  const onUserSellerChange = (val: boolean) => {
    setIsUserSeller(val);
  };

  const onUserAgencyChange = (val: boolean) => {
    setIsUserAgency(val);
  };

  const onUserAdminChange = (val: boolean) => {
    setIsUserAdmin(val);
  };

  const handleSelectOrg = (org: OrgDataType | null) => {
    setSelectedOrg(org || EMPTY_ORG);
  };

  const onOrgKeywordChange = (search: string) => {
    setOrgSearchTerm(search);
  };

  const handleCreateNewUser = async () => {
    const newUser: CreateUserRequest = {
      firstName: firstName,
      lastName: lastName,
      organizationId: selectedOrg.id,
      email: email,
      isAdmin: isUserAdmin,
      isBuyer: isUserBuyer,
      isSeller: isUserSeller,
      isAgency: isUserAgency,
    };

    const result = await UsersService.createUser(newUser);
    if (result) {
      hideCreateUserModal();
      setNotifications([NotificationHelper.success("User was successfully created.")]);
      setRefresh(refresh + 1);
    } else {
      setNotifications([NotificationHelper.error("Unable to create user, please try again.")]);
    }
  };

  const bulkOptions: DropdownItem[] = [
    {
      id: "assign",
      value: "Assign Orgs",
    },
    {
      id: "unassign",
      value: "Unassign Orgs",
    },
  ];

  const hideOrgsModal = () => {
    setActive(false);
  };

  const handleSetSelectedUsers = (users: UserDataType[]) => {
    setSelectedUsers(users);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    switch (bulkActionItem.id) {
      case "assign":
        setIsAssignMode(true);
        setActive(true);
        break;
      case "unassign":
        setIsAssignMode(false);
        setActive(true);
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <PageHeader title="Users"></PageHeader>
      <UsersTable
        button={<Button label={"Create User"} icon={""} onClick={showCreateUserModal} width={"120px"}></Button>}
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        onSelectUsers={handleSetSelectedUsers}
        refresh={refresh}
      ></UsersTable>
      {createUserModalVisible && (
        <Modal
          width="500px"
          buttons={[
            <Button label="Cancel" onClick={hideCreateUserModal} type="text" key="cancelCreateUserBtn"></Button>,
            <Button
              disabled={disableCreateUserBtn}
              type="primary"
              key="createUserBtn"
              dataTestId="createUserBtn"
              label="Create"
              onClick={handleCreateNewUser}
            ></Button>,
          ]}
          closeOnBlur
          closeCallback={hideCreateUserModal}
          title="Create New User"
          active={createUserModalVisible}
        >
          <ModalGrid>
            <TextField
              label="First Name"
              defaultValue=""
              placeholder=""
              onChange={onFirstNameChange}
              errorText={firstNameError}
            />
            <TextField
              label="Last Name"
              defaultValue=""
              placeholder=""
              onChange={onLastNameChange}
              errorText={lastNameError}
            />
            <TextField
              label="Email Address"
              defaultValue=""
              placeholder=""
              onChange={onEmailChange}
              errorText={emailError}
            />
            <div>
              <FormLabel>Select Organization</FormLabel>
              <SearchWrapper>
                <KeywordSearch
                  onSelect={handleSelectOrg}
                  items={orgDropdownItems}
                  label="Select Organization"
                  onChange={onOrgKeywordChange}
                ></KeywordSearch>
              </SearchWrapper>
            </div>
            <Checkbox label="Is this user an admin?" onChange={onUserAdminChange} />
            <div>
              <p>
                <FormLabel>User Type (select at least one):</FormLabel>
              </p>
              <Checkbox label="Buyer" onChange={onUserBuyerChange} />
            </div>
            <Checkbox label="Seller" onChange={onUserSellerChange} />
            <Checkbox label="Agency" onChange={onUserAgencyChange} />
          </ModalGrid>
        </Modal>
      )}
      <Notifications notifications={notifications} duration={4000} />
      <BulkModal
        onCancel={hideOrgsModal}
        selectedUsers={selectedUsers}
        selectedClaims={[]}
        selectedOrgs={[]}
        currentStepKey={StepEnums.SelectOrgs}
        mode={isAssignMode ? BulkModes.AssignOrgs : BulkModes.UnassignOrgs}
        active={active}
      />
    </Wrapper>
  );
};
