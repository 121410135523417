import { Button, Notifications } from "@integrate/hedgehogger";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import {
  DataTable,
  DefaultDataTableParams,
  IDataTableHeader,
  IDataTableParams,
  IParsedDataTableRow,
  TableSelection,
} from "../dataTable/DataTable";
import { UsersService, OrgClaim, UserDataType } from "../common/services/UsersService";
import { StyledLink } from "../styledLink/StyledLink";
import { DropdownItem } from "@integrate/hedgehogger/lib/components/dropdown/DropdownOptions";
import { OrgDataType, OrgService } from "../common/services/OrgService";
import {
  ClaimDataType,
  ClaimsDataType,
  ClaimsService,
  DeleteOrgClaimsBody,
} from "../common/services/ClaimsService";
import { useHistory } from "react-router-dom";
import { BulkModal, BulkModes, StepEnums } from "../common/components/BulkModal/BulkModal";
import { ConfirmationModal } from "../common/components/ConfirmationModal/ConfirmationModal";
import { NotificationHelper } from "../common/helpers/NotificationHelper";
import { TableHelper } from "../common/helpers/TableHelper";

interface UserOrgsProps {
  user: UserDataType;
  refreshData: (tabIndex: number) => void;
}

export const UserOrgs: FunctionComponent<UserOrgsProps> = ({ user, refreshData }) => {
  const [notifications, setNotifications]: any[] = useState([]);
  const [tableData, setTableData] = useState<IParsedDataTableRow[]>([]);
  const [totalOrgs, setTotalOrgs] = useState(0);
  const [params, setParams] = useState<IDataTableParams>(DefaultDataTableParams);
  const [selectedOrgs, setSelectedOrgs] = useState<OrgDataType[]>([]);
  const [selectedClaims, setSelectedClaims] = useState<ClaimDataType[]>([]);
  const history = useHistory();
  const [isAssignMode, setIsAssignMode] = useState(false);
  const [active, setActive] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepEnums>(StepEnums.SelectClaims);
  const [orgsData, setOrgsData] = useState<OrgDataType[]>(
    UsersService.transformOrgClaimToData(user.organizationClaims)
  );
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState<OrgClaim>();
  const [verifiedClaimsSet, setVerifiedClaimsSet] = useState<Set<string>>(new Set<string>());
  const [sanitizedOrgClaims, setSanitizedOrgClaims] = useState<OrgClaim[]>();

  const getClaims = async (): Promise<ClaimsDataType> => {
    const response = await ClaimsService.getClaims(ClaimsService.GET_ALL_CLAIMS_PARAMS);
    return response;
  };

  const getNumberOfClaims = (orgClaim: OrgClaim): ReactElement => {
    let numOfClaims = 0;
    let claimStr = "";
    if (orgClaim) {
      numOfClaims = orgClaim.claims.length;
      claimStr = orgClaim.claims.join(", ");
    }
    return <div title={claimStr}>{numOfClaims}</div>;
  };

  const transformResults = () => {
    const orgClaims = sanitizedOrgClaims ? sanitizedOrgClaims : user.organizationClaims;
    setOrgsData(UsersService.transformOrgClaimToData(orgClaims));

    const parsedData: IParsedDataTableRow[] = orgClaims.map((orgClaim: OrgClaim) => {
      const org = orgClaim.organization;
      const isChecked = TableHelper.checkIdExistsInArray(org.id, selectedOrgs);

      const parsedRow: IParsedDataTableRow = {
        id: orgClaim.organization.id,
        isDefaultChecked: isChecked,
        actions: [
          {
            label: "Edit",
            action: () => handleEditOrg(org.id),
          },
          {
            label: "Edit Claims",
            action: () => handleEditClaims(orgClaim),
          },
          {
            label: "Unassign",
            action: () => handleOrgUnassignConfirmation(orgClaim),
          }
        ],
        columns: [
          {
            id: "Name",
            value: <StyledLink to={`/orgs/${org.id}`}>{org.name}</StyledLink>,
          },
          {
            id: "ShortName",
            value: org.shortName,
          },
          {
            id: "Description",
            value: org.description,
          },
          {
            id: "Id",
            value: <StyledLink to={`/orgs/${org.id}`}>{org.id}</StyledLink>,
          },
          {
            id: "NumOfClaims",
            value: getNumberOfClaims(orgClaim),
          },
        ],
      };

      return parsedRow;
    });

    const sortedData = OrgService.sort(parsedData, params);
    const searchedData = OrgService.searchRows(sortedData, params.search);
    const filteredData = searchedData.slice(params.skip, params.take + params.skip);

    setTableData(filteredData);
    setTotalOrgs(searchedData.length);
  };

  useEffect(() => {
    const fetchClaims = async () => {
      const response = await getClaims();
      if (response?.success && response?.claims) {
        // Convert to simple string array of claim keys.
        let claimsArray = response.claims.map((c) => c.key);
        // Append non-feature claim keys to the array. E.g. User, Buyer, Seller, etc.
        claimsArray = claimsArray.concat(ClaimsService.NON_FEATURE_CLAIMS);
        // Converts array to the Set data type.
        const claimsSet = ClaimsService.buildSanitizedClaimsSet(claimsArray);
        setVerifiedClaimsSet(claimsSet);
      }
    }
    fetchClaims();
  }, []);

  useEffect(() => {
    // Re-sanitize org claims whenever claims data were fetched.
    const sanitizedClaims = sanitizeUserOrgsClaims(user.organizationClaims, verifiedClaimsSet);
    setSanitizedOrgClaims(sanitizedClaims);
  }, [user, verifiedClaimsSet]);

  const sanitizeUserOrgsClaims = (orgClaims: OrgClaim[], claimsSet: Set<string>): OrgClaim[] => {
    let sanitizedOrgs = orgClaims;
    if (orgClaims.length && claimsSet.size > 0) {
      sanitizedOrgs = [];
      orgClaims.forEach((org: OrgClaim) => {
        const sanitizedSet = ClaimsService.buildSanitizedClaimsSet(org.claims, verifiedClaimsSet)
        org.claims = Array.from(sanitizedSet.values());
        sanitizedOrgs.push(org);
      });
    }
    return sanitizedOrgs;
  };

  useEffect(() => {
    transformResults();
  }, [
    params.search,
    params.skip,
    params.take,
    params.sortBy,
    params.sortOrder,
    user,
    selectedOrgs,
    sanitizedOrgClaims,
  ]);

  const columns: IDataTableHeader[] = [
    {
      label: "Name",
      id: "Name",
    },
    {
      label: "Short Name",
      id: "ShortName",
    },
    {
      label: "Description",
      id: "Description",
    },
    {
      label: "ID",
      id: "Id",
      disableSort: true,
    },
    {
      label: "# of Claims",
      id: "NumOfClaims",
    },
  ];

  const bulkOptions: DropdownItem[] = [
    {
      id: "assign",
      value: "Assign Claims",
    },
    {
      id: "unassign",
      value: "Unassign Orgs",
    },
  ];

  const handleEditOrg = (orgId: string) => {
    history.push(`/orgs/${orgId}`);
  };

  const handleEditClaims = (orgClaim: OrgClaim) => {
    if (orgClaim) {
      const orgData: OrgDataType = {
        ...orgClaim.organization,
        crmAccountIds: [],
      };
      const selectedClaims: ClaimDataType[] = [];
      if (orgClaim.claims && orgClaim.claims.length > 0) {
        orgClaim.claims.forEach((claim) => {
          selectedClaims.push({
            key: claim,
            label: claim,
            id: claim,
            description: "",
            stage: "",
          });
        });
      }
      setCurrentStep(StepEnums.SelectClaims);
      setSelectedClaims(selectedClaims);
      setSelectedOrgs([orgData]);
      setIsAssignMode(true);
      setActive(true);
    }
  };

  const handleUnassignOrg = async () => {
    setShowConfirmationModal(false);
    if(orgToDelete) {
      const body: DeleteOrgClaimsBody = {
        userIds: [user.id],
        organizationIds: [orgToDelete.organization.id],
      };
      try {
        const results = await ClaimsService.deleteOrgClaims(body);
        if (!results) {
          throw new Error("error deleting org from the user");
        }
        setNotifications([NotificationHelper.success("Deleted one org from the user.")]);
        refreshData(1);
      } catch (e) {
        setNotifications([NotificationHelper.error("Unable to delete org, please try again.")]);
      }
    }
  };

  const handleOrgUnassignConfirmation = (org: OrgClaim) => {
    setShowConfirmationModal(true);
    setOrgToDelete(org);
  }

  const handleChangeParams = (newParams: IDataTableParams) => {
    setParams(newParams);
  };

  const handleHideConfirmModal = () => {
    setShowConfirmationModal(false);
  }

  const handleSetSelectedItems = (selectedItems: TableSelection[]) => {
    const newSelection = TableHelper.calcSelectedRows(selectedOrgs, selectedItems, orgsData);
    setSelectedOrgs(newSelection);
  };

  const handleCreateOrg = async () => {
    setSelectedClaims([]);
    setIsAssignMode(true);
    setCurrentStep(StepEnums.SelectOrgs);
    setActive(true);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    setCurrentStep(StepEnums.SelectClaims);
    setSelectedClaims([]);
    switch (bulkActionItem.id) {
      case "assign":
        setIsAssignMode(true);
        setActive(true);
        break;
      case "unassign":
        setIsAssignMode(false);
        setActive(true);
        break;
      default:
        break;
    }
  };

  const hideBulkModal = (refresh: boolean) => {
    setActive(false);
    setSelectedOrgs([]);
    if (refresh) {
      refreshData(1);
    }
  };

  return (
    <div>
      <DataTable
        button={
          <Button
            onClick={handleCreateOrg}
            label="Add Organization"
            minWidth="84px"
            width="70%"
            maxWidth="210px"
            dataTestId="addOrgButton"
          ></Button>
        }
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        numSelectedItems={selectedOrgs.length}
        name="User's Orgs"
        columns={columns}
        data={tableData}
        onChangeParams={handleChangeParams}
        onSelectItems={handleSetSelectedItems}
        totalAmount={totalOrgs}
        showPagination
        popoverWidth="120px"
      ></DataTable>
      <BulkModal
        onCancel={hideBulkModal}
        selectedOrgs={selectedOrgs}
        selectedUsers={[user]}
        selectedClaims={selectedClaims}
        currentStepKey={currentStep}
        active={active}
        mode={isAssignMode ? BulkModes.AssignOrgs : BulkModes.UnassignOrgs}
      />
      {showConfirmationModal && <ConfirmationModal
        label={`${user.userName} will be unassigned from ${orgToDelete?.organization.name}.`}
        active={showConfirmationModal}
        onCancel={handleHideConfirmModal}
        onConfirm={handleUnassignOrg}
        />
      }
      <Notifications notifications={notifications} duration={4000} />
    </div>
  );
};
