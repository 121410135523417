import { FunctionComponent } from "react";
import styled from "styled-components";
import { TextField } from "@integrate/hedgehogger";
import { UserDataType } from "../common/services/UsersService";

type UserGeneralInfoProps = {
  data: UserDataType;
};

const Grid2x2 = styled.div`
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`;

const Spacer = styled.span`
  height: 24px;
  display: block;
`;

export const UserGeneralInfo: FunctionComponent<UserGeneralInfoProps> = ({
  data,
}) => {
  const id = data.id || "";
  const firstName = data.contact?.firstName || "";
  const lastName = data.contact?.lastName || "";
  const userName = data.userName;
  const emailAddress = data?.contact?.email[0].address || "";

  return (
    <div>
      <Spacer />
      <Grid2x2>
        <TextField
          label="First Name"
          defaultValue={firstName}
          placeholder=""
          disabled
        />
        <TextField
          label="Last Name"
          defaultValue={lastName}
          placeholder=""
          disabled
        />
        <TextField
          label="Username"
          defaultValue={userName}
          placeholder=""
          disabled
        />
        <TextField
          label="Email Address"
          defaultValue={emailAddress}
          placeholder=""
          disabled
        />
        <TextField label="ID" defaultValue={id} placeholder="" disabled />
      </Grid2x2>
    </div>
  );
};
