import { FunctionComponent, useState, useEffect } from "react";
import { Button, Notifications, Modal, TextField, Dropdown } from "@integrate/hedgehogger";
import { DropdownItem } from "@integrate/hedgehogger/lib/components/dropdown/DropdownOptions";
import styled from "styled-components";
import {
  OrgService,
  OrgDataType,
  OrgDispositionType,
} from "../../common/services/OrgService";
import {
  DataTable,
  IDataTableHeader,
  IDataTableParams,
  IParsedDataTableRow,
  DefaultDataTableParams,
  TableSelection,
} from "../../dataTable/DataTable";
import { SaveButtonWrapper } from "../../shared/StyledComponents";

const BodyWrapper = styled.div`
padding-bottom: 72px;
`;

const ModalGrid = styled.div`
align-items: start;
display: grid;
grid-template-columns: 1fr;
row-gap: 16px;
justify-items: stretch;
`;

type OrgDispositionProps = {
  data: OrgDataType;
};

const tableColumns: IDataTableHeader[] = [
  {
    id: "Name",
    label: "Name",
  },
  {
    id: "Status",
    label: "Status",
  },
];

const bulkOptions: DropdownItem[] = [
  {
    id: "delete",
    value: "Delete Selected",
  },
];

const dispNameRegex = /^[a-zA-Z0-9]+([_-][a-zA-Z0-9]+)*$/;

export const OrgDispositions: FunctionComponent<OrgDispositionProps> = ({data}) => {
  const [orgDisps, setOrgDisps] = useState<OrgDispositionType>(data.dispositionMap || {});
  const [notifications, setNotifications] = useState<any[]>([]);
  const [tableData, setTableData] = useState<IParsedDataTableRow[]>([]);
  const [sortAndSearchedTableData, setSortAndSearchedTableData] = useState<
    IParsedDataTableRow[]
  >([]);
  const [selectedDispItems, setSelectedDispItems] = useState<string[]>([]);
  const [addDispModalVisible, setAddDispModalVisible] = useState(false);
  const [newDispName, setNewDispName] = useState("");
  const [newDispStatus, setNewDispStatus] = useState("");
  const [salesforceIds, setSalesforceIds] = useState("");
  const [newDispNameError, setNewDispNameError] = useState("");
  const initialDropdownItems: DropdownItem[] = [
    {id: "accepted", value: "Accepted"},
    {id: "rejected", value: "Rejected"},
    {id: "failed", value: "Failed"},
  ];
  const [dropDownItems, setDropdownItems] = useState<DropdownItem[]>(initialDropdownItems)

  useEffect(() => {
    if (Array.isArray(data.crmAccountIds)) {
      setSalesforceIds(data.crmAccountIds.join(", "));
    } else {
      setSalesforceIds("");
    }
  }, [data.crmAccountIds])

  const handleOnSaveClicked = async () => {
    const transformed = OrgService.transformOrgDataForSaving(
      data.id,
      data.shortId,
      data.name,
      data.shortName,
      data.description,
      data.subscriptionId ?? "",
      salesforceIds,
      data.isAgency,
      data.isDAPActive,
      orgDisps
    );
    const response = await OrgService.saveOrg(transformed);

    if (response && response.organization) {
      setNotifications([
        ...notifications,
        {
          title: "Save Successful",
          text: `Dispositions has been updated.`,
          type: "success",
          hasClose: false,
        },
      ]);
    } else {
      setNotifications([
        ...notifications,
        {
          title: "Save Failed",
          text: `There's an error saving dispositions.`,
          type: "error",
          hasClose: false,
        },
      ]);
    }
  };

  useEffect(() => {
    transformOrgDispsToTableData();
  }, [orgDisps, selectedDispItems]);

  const handleDeleteDispositions = (keys: string[]): void => {
    const newDisps = OrgService.deleteDispositions(keys, orgDisps);
    setOrgDisps(newDisps);
    setSelectedDispItems([]);
  };

  const transformOrgDispsToTableData = () => {
    const parsedData: IParsedDataTableRow[] = [];

    Object.entries(orgDisps).forEach((entry) => {
      const key = entry[0];
      const dispItem = entry[1];
      const disp: OrgDispositionType = {};
      disp[key] = dispItem;

      let isChecked = false;
      for (let i = 0; i < selectedDispItems.length; i++) {
        if (selectedDispItems[i] === key) {
          isChecked = true;
        }
      }

      const parsedRow: IParsedDataTableRow = {
        id: key,
        isDefaultChecked: isChecked,
        actions: [
          {
            label: "Delete",
            action: () => handleDeleteDispositions([key]),
          },
        ],
        columns: [
          {
            id: "Name",
            value: key,
          },
          {
            id: "Status",
            value: dispItem.status,
          },
        ],
      };

      parsedData.push(parsedRow);
    });

    setTableData(parsedData);
    let newData = [...parsedData];
    const sortParams: IDataTableParams = { ...DefaultDataTableParams };
    sortParams.sortBy = "Name";
    newData = OrgService.sort(newData, sortParams);
    setSortAndSearchedTableData(newData);
  };

  const handleSelectItems = (items: TableSelection[]) => {
    const newDisps: string[] = [];
    const currentDisps: string[] = [...selectedDispItems];

    items.forEach((item: TableSelection) => {
      if (item.isSelected) {
        const alreadySelected = selectedDispItems.some((item: any, index: number) => {
          return selectedDispItems[index] === item.id
        });

        if (!alreadySelected && item.id) {
          newDisps.push(item.id);
        }
      } else {
        const index = currentDisps.findIndex((key) => {
          return item.id === key;
        })
        currentDisps.splice(index, 1);
      }
    })
    setSelectedDispItems(currentDisps.concat(newDisps));
  };

  const showAddDispModal = (): void => {
    setAddDispModalVisible(true);
  };

  const hideAddDispModal = (): void => {
    setNewDispName("");
    setNewDispStatus("");
    setNewDispNameError("");
    setDropdownItems([...initialDropdownItems]);
    setAddDispModalVisible(false);
  };

  const handleChangeParams = (newParams: IDataTableParams): void => {
    let newData = [...tableData];
    newData = OrgService.searchRows(newData, newParams.search);
    newData = OrgService.sort(newData, newParams);
    setSortAndSearchedTableData(newData);
  };

  const handleBulkAction = (bulkActionItem: DropdownItem) => {
    if (bulkActionItem.id === "delete") {
      handleDeleteDispositions(selectedDispItems);
    }
  };

  const handleNewDispNameChange = (val: string) => {
    setNewDispName(val);

    const isValid = dispNameRegex.test(val);
    if (!isValid) {
      setNewDispNameError("Only alphanumeric characters allowed with underscores or dashes as dividers");
    } else {
      setNewDispNameError("");
    }
  };

  const handleNewDispStatusChange = (val: DropdownItem) => {
    const newDropdownItems = [...initialDropdownItems];
    newDropdownItems.forEach((item) => {
      if(item.value === val.value){
        item.isSelected = true;
        setNewDispStatus(val.value);
      }
    });
    setDropdownItems(newDropdownItems);
  };

  const handleCreateNewDisp = () => {
    const modifiedDisps = {...orgDisps};
    modifiedDisps[newDispName] = {status: newDispStatus};
    setOrgDisps(modifiedDisps);
    setDropdownItems([...initialDropdownItems]);
    hideAddDispModal();
  };

  return (
    <BodyWrapper>
      <SaveButtonWrapper>
        <Button label="Save" onClick={handleOnSaveClicked} />
      </SaveButtonWrapper>
      <DataTable
        button={
          <Button
            onClick={showAddDispModal}
            label="Add Disposition"
            minWidth="84px"
            width="70%"
            maxWidth="210px"
          ></Button>
        }
        bulkActionOptions={{
          onBulkItemClicked: handleBulkAction,
          bulkActionItems: bulkOptions,
        }}
        name="Dispositions"
        columns={tableColumns}
        onSelectItems={handleSelectItems}
        data={sortAndSearchedTableData}
        onChangeParams={handleChangeParams}
        totalAmount={Object.keys(orgDisps).length}
        popoverWidth="80px"
        showPagination={false}
        numSelectedItems={selectedDispItems.length}
      ></DataTable>
      { addDispModalVisible &&
        <Modal
          width="500px"
          buttons={[
            <Button
              label="Cancel"
              onClick={hideAddDispModal}
              type="text"
              key="cancelbutton"
            ></Button>,
            <Button
              disabled={!!newDispNameError || !newDispName || !newDispStatus}
              type="primary"
              key="addbutton"
              dataTestId="addbutton"
              label="Add"
              onClick={handleCreateNewDisp}
            ></Button>,
          ]}
          closeOnBlur
          closeCallback={hideAddDispModal}
          title="Add Disposition"
          active={addDispModalVisible}
        >
            <ModalGrid>
              <p>If you entered an existing disposition name, the previous value will be overwritten.</p>
              <TextField
                label="Name"
                defaultValue=""
                placeholder="New disposition name"
                onChange={handleNewDispNameChange}
                errorText={newDispNameError}
              />
              <Dropdown
                form
                items={dropDownItems}
                onClick={handleNewDispStatusChange}
                label="Status"
                popoverWidth={"260px"}
                dataTestId="disp-status-dropdown"
              >
              </Dropdown>
            </ModalGrid>
        </Modal>
      }
      <Notifications notifications={notifications} duration={4000} />
    </BodyWrapper>
  );
};
